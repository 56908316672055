import * as React from "react";
import {useState} from "react";
import 'bootstrap/dist/css/bootstrap.css'
import Logo from '../../images/lifeguide-logo.png';
import FooterLogo from '../../images/logo-footer.png';
import "@fontsource/inter"
import "../../styles/main.scss"
import markerIcon from '../../images/marker.png'
import phoneIcon from '../../images/phone.png'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Modal from 'react-bootstrap/Modal'
import Photo1 from '../../images/photo-1.png';
import Mailchimp from './mailchimp'
import Navbar from 'react-bootstrap/Navbar'
import twitter from '../../images/twt.svg'
import fb from '../../images/fb.svg'
import ig from '../../images/ig.svg'
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { useEffect } from "react";
import { Link } from 'gatsby';
import parse from 'html-react-parser';

// styles
const pageStyles = {
  color: "#232129",
}

const logoStyle = {
  maxWidth: '143px'
}

const redirectHome = () => {
  window.location.href = `${process.env.HOME_URL_PATH}`
}

const redirectLogin = () => {
  window.location.href = `${process.env.GATSBY_URL_PATH}/users/sign_in`
}

const redirectSignup = () => {
  window.location.href = `${process.env.GATSBY_URL_PATH}/users/sign_up`
}

const redirectSubscribe = () => {
  window.location.href = `${process.env.GATSBY_URL_PATH}/subscription`
}

const redirectSocialMedia = (sc) => {
  window.location.href = parse(sc)[1].props.children.props.href
}

const formActionUrl = process.env.MAILCHIMP_FORM

const allPostWp = graphql`
  query GETMAINMENU {
    allWpCategory {
      nodes {
        name
        posts {
          nodes {
            id
            title
            excerpt
            content
            featuredImage {
              node {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
    allWpMediaItem {
      nodes {
        id
        parentId
        mediaItemUrl
      }
    }
    wpCategory(name: {eq: "Footer"}) {
      posts {
        nodes {
          content
        }
      }
    }
  }
`

// markup
const ResourcePage = () => {
  const [show, setShow] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (img) => { 
    setShow(true);
    setImageUrl(img);
  }

  useEffect(() => {
    const imgEl = document.getElementsByClassName('lazyload');
    const wpAudio = document.getElementsByTagName('audio');
    for (var i=0; i<imgEl.length; i++) {
      if(imgEl[i].getAttribute('data-src')) {
        imgEl[i].removeAttribute('src'); //use only if you need to remove data-src attribute after setting src
        imgEl[i].setAttribute('src',imgEl[i].getAttribute('data-src'));
        imgEl[i].removeAttribute('data-src'); //use only if you need to remove data-src attribute after setting src
      }
    }
    for (var i=0; i<wpAudio.length; i++) {
      if(wpAudio[i].getAttribute('src')) {
        wpAudio[i].setAttribute('src', wpAudio[i].getAttribute('src').replace('https://wp.lifeguideconsultancy.com', 'https://reverse-proxy-lifeguide.herokuapp.com'));
      }
    }
  });
  return (
    <main style={pageStyles}>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Lifeguide Consultancy</title>
        </Helmet>
      </div>
      <section className="container-fluid">
        <div className="row">
          <div className="col-12 p-0">
            <section className="first-section">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <Navbar collapseOnSelect expand="lg">
                      <Navbar.Brand href="#home" onClick={() => redirectHome()}>
                        <img src={Logo} style={logoStyle} className='w-100' />
                      </Navbar.Brand>
                      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                      <Navbar.Collapse id="responsive-navbar-nav">
                        <ul className="navbar-nav mx-auto">
                          <li className="nav-item">
                            <a className="nav-link" href="/">Home</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/#about-us">About Us</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="" onClick={() => redirectSubscribe()}>Subscribe</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/#how-it-works">How it works</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/#faq">FAQ</a>
                          </li>
                        </ul>
                        <div className="form-inline my-2 my-lg-0">
                          <button className="btn btn-ghost mr-3" onClick={() => redirectLogin()}>Sign in</button>
                          <button className="btn btn-signup my-2 my-sm-0" onClick={() => redirectSignup()}>Sign up</button>
                        </div>
                      </Navbar.Collapse>
                    </Navbar>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <h1 className="text-center custom-margin-bottom">Resource</h1>
                    <Modal show={show} onHide={handleClose} size="lg" centered>
                      <img src={imageUrl.replace("http://wp.lifeguideconsultancy.com", "https://reverse-proxy-lifeguide.herokuapp.com")} className="img-border"/>
                    </Modal>
                  </div>
                </div>
              </div>
            </section>
            <StaticQuery
              query={allPostWp}
              render={(data) => {
                if (data.allWpCategory) {
                  const posts = data.allWpCategory.nodes
                  const photos = data.allWpMediaItem.nodes
                  const footer = data.wpCategory.posts
                  return (
                    <div>
                      <section className="section-content mb-4">
                        <div className="container">
                          <div className="row">
                            <div className="col-12">
                              <Tabs
                                defaultActiveKey="seminar"
                                transition={false}
                                id="noanim-tab-example"
                                className="mb-3"
                              >
                                <Tab eventKey="seminar" title="Seminar">
                                  <div className="row">
                                    {photos && photos.map((photo) => {
                                      if (photo.parentId === process.env.GALLERY_POST_ID) {
                                        return (
                                          <div className="col-6 col-lg-4 mb-3">
                                            <img src={photo.mediaItemUrl.replace("http://wp.lifeguideconsultancy.com", "https://reverse-proxy-lifeguide.herokuapp.com")} className="img-border w-100" onClick={e => handleShow(photo.mediaItemUrl)}/>
                                          </div>
                                        )
                                      }
                                    })}
                                  </div>
                                </Tab>
                                <Tab eventKey="article" title="Article">
                                  <div className="row">
                                    {posts && posts.map((post) => {
                                      if (post.name === "Article") {
                                        return post.posts.nodes.map((article) => {
                                          return (
                                            <div className="col-12 col-lg-4 col-md-6 mb-4">
                                              <div className="card">
                                                <img className="w-100" src={ article.featuredImage ? article.featuredImage.node.mediaItemUrl.replace("http://wp.lifeguideconsultancy.com", "https://reverse-proxy-lifeguide.herokuapp.com") : Photo1 } alt="Card image cap" />
                                                <div className="card-body">
                                                  <h5 className="card-title">{article.title}</h5>
                                                  <Link to={`/resource/detail`} state={{ title: article.title, content: article.content }} style={{textDecoration: 'none'}}>
                                                    <div dangerouslySetInnerHTML={{ __html: article.excerpt }} />
                                                  </Link>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        })
                                      }
                                    })}
                                  </div>
                                </Tab>
                                <Tab eventKey="radio" title="Radio">
                                  <div className="row">
                                    { posts && posts.map((post) => {
                                      if (post.name === "Radio") {
                                        return post.posts.nodes.map((radio) => {
                                          return (
                                            <div className="col-12 mb-4">
                                              <div className="card">
                                                <div className="card-body">
                                                  <div className="flex-container">
                                                    <div className="ml-3 w-100">
                                                      <h5 className="card-title radio">{radio.title}</h5>
                                                    </div>
                                                    <div dangerouslySetInnerHTML={{ __html: radio.content }} />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        })
                                      }
                                    })
                                    }
                                  </div>
                                </Tab>
                              </Tabs>
                            </div>
                          </div>
                        </div>
                      </section>
                      <footer className="page-footer pt-0">
                        <div className="bg-footer-dark">
                          <div className="container">
                            <div className="row p-0">
                              <div className="col-12 col-lg-4 mb-4">
                                <h6 className="text-uppercase font-weight-bold mb-3">
                                  <img src={FooterLogo} style={logoStyle} className='w-100' />
                                </h6>
                                <div className="row">
                                  <div className="col-2">
                                    <img src={phoneIcon} alt="phone" />
                                  </div>
                                  <div className="col-10 text-gray">
                                    <div dangerouslySetInnerHTML={{ __html: footer.nodes[3].content }} />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-2">
                                    <img src={markerIcon} alt="marker" />
                                  </div>
                                  <div className="col-10 text-gray">
                                    <div dangerouslySetInnerHTML={{ __html: footer.nodes[4].content }} />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 col-lg-2 mb-4">
                                <h6 className="text-uppercase font-weight-bold">Company</h6>
                                <p>
                                  <a href="/#head">Home</a>
                                </p>
                                <p>
                                  <a href="/#about-us">About Us</a>
                                </p>
                                <p>
                                  <a href="" onClick={() => redirectSubscribe()}>Subscribe</a>
                                </p>
                                <p>
                                  <Link to="/resource">Resources</Link>
                                </p>
                                <p>
                                  <Link to="/privacyPolicy">Privacy Policy</Link>
                                </p>
                              </div>
                              <div className="col-6 col-lg-2 mb-4">
                                <h6 className="text-uppercase font-weight-bold">Help</h6>
                                <p>
                                  <a href="/#how-it-works">How it works</a>
                                </p>
                                <p>
                                  <a href="/#faq">FAQ</a>
                                </p>
                              </div>
                              <div className="col-12 col-lg-4 mb-4">
                                <h6 className="text-uppercase font-weight-bold">Subscribe to our newsletter</h6>
                                <p className="text-white">The latest news, articles, and resources, sent to your inbox weekly.</p>
                                <Mailchimp
                                  action={formActionUrl}
                                  fields={[
                                    {
                                      name: 'EMAIL',
                                      placeholder: 'Email Address',
                                      type: 'email',
                                      required: true
                                    }
                                  ]}
                                />
                              </div>
                            </div>
          
                            <hr className="bg-gray" />
                            <div className="row pt-2 pb-4">
                              <div className="col-6">
                                <p className="text-gray">&copy;  
                                2021
                                Lifeguideconsultancy. All rights reserved.</p>
                              </div>
                              <div className="col-6 text-right footer-sns">
                                <img src={fb} onClick={() => redirectSocialMedia(footer.nodes[2].content)}/>
                                <img src={ig} onClick={() => redirectSocialMedia(footer.nodes[1].content)}/>
                                <img src={twitter} onClick={() => redirectSocialMedia(footer.nodes[0].content)}/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </footer>
                    </div>
                  )
                }
              }}
            />
          </div>
        </div>
      </section>
    </main>
  )
}

export default ResourcePage;
